import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get, findIndex } from 'lodash'

import PageLayout from '../components/page-layout'
import { SectionText } from '../components/section'
import { GridSection } from '../components/grid'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'
import { formatImageForARYImage } from '../lib/_helpers'
import {
  ThumbnailImageFragment,
  FullScreenImageFragment,
} from '../lib/fragments'

import { openOverlay } from '../redux/actions'

import iIntro from '../assets/imgs/home/intro_about.png'
import iIntroMob from '../assets/imgs/home/intro_about_mobile.png'

class PageAbout extends React.Component {
  constructor(props) {
    super(props)

    this.onSelectTeamMember = this.onSelectTeamMember.bind(this)
    this.onSelectProject = this.onSelectProject.bind(this)
  }

  onSelectTeamMember(index, side = null) {
    const { data, dispatch } = this.props

    const team = get(data, 'team.edges')
    const teamMember = team ? team[index] : null

    if (teamMember) {
      dispatch(
        openOverlay({
          type: 'teamMember',
          panelSide: side === 'right' ? 'left' : null,
          colour: 'purpleLight',
          data: teamMember.node,
        })
      )
    }
  }

  onSelectProject(index) {
    const { data, dispatch } = this.props

    const projects = get(data, 'projects.edges')
    const project = projects ? projects[index] : null

    if (project) {
      dispatch(
        openOverlay({
          type: 'projectGallery',
          colour: 'purpleLight',
          data: project.node,
        })
      )
    }
  }

  calcRowsForTeam() {
    const { screenSize } = this.props

    if (screenSize.width <= breakpoints.mobile) {
      return 2
    } else if (screenSize.width <= breakpoints.tabletPortrait) {
      return 3
    } else if (screenSize.width <= breakpoints.tablet) {
      return 4
    } else {
      return 6
    }
  }

  calcRowsForProjects() {
    const { screenSize } = this.props

    if (screenSize.width <= breakpoints.mobilePortrait) {
      return 1
    } else if (screenSize.width <= breakpoints.tabletPortrait) {
      return 2
    } else {
      return 3
    }
  }

  render() {
    const { data, screenSize, overlay, location, fadeWait } = this.props

    const team = get(data, 'team.edges')
    const projects = get(data, 'projects.edges')

    const activeTeamMemberID =
      overlay && overlay.type === 'teamMember'
        ? overlay.data.wordpress_id
        : null
    const activeTeamMemberIndex = activeTeamMemberID
      ? findIndex(team, (o) => {
          return o.node.wordpress_id === activeTeamMemberID
        })
      : null

    return (
      <PageLayout
        seo={getSEOFromPage(get(data, 'page'))}
        location={location}
        pageIntroTitle="About us"
        pageIntroImage={{
          src: iIntro,
          width: 2560,
          height: 1600,
        }}
        pageIntroImageMobile={{
          src: iIntroMob,
          width: 960,
          height: 1200,
        }}
        pageIntroAlignment="bottom"
        pageIntroColour="purpleLight"
        showFooter={true}
      >
        <SectionText
          elID="team"
          title="Meet the team"
          body={`
					<p>We’re a team dedicated to changing housing for the better. So that people can live better, healthier, more fulfilled lives. And, we’ve spent decades figuring out how to do it.</p>
					`}
          fadeWait={fadeWait}
          bgColour="purpleLight"
          headerBgColour={true}
          showIndicator={true}
        />
        <GridSection
          colBase={this.calcRowsForTeam()}
          firstRowMax={3}
          title="Our team."
          elTitleAsBody={true}
          onClickElement={this.onSelectTeamMember}
          activeIndex={activeTeamMemberIndex}
          elements={
            team && team.length > 0
              ? team.map((_node, _i) => {
                  return {
                    title: _node.node.title,
                    image: formatImageForARYImage(_node.node.acf.image),
                  }
                })
              : null
          }
        />
        <SectionText
          elID="past-projects"
          title="Past projects"
          body={`
					<p>We’ve spent decades building amazing award-winning homes.</p>
					<p>Enjoy a selection below.</p>
				`}
          bgColour="purpleLight"
          headerBgColour={true}
        />
        <GridSection
          colBase={this.calcRowsForProjects()}
          limitRatio={true}
          title="Past projects"
          onClickElement={this.onSelectProject}
          elements={
            projects && projects.length > 0
              ? projects.map((_node, _i) => {
                  return {
                    title: _node.node.title,
                    image: formatImageForARYImage(_node.node.acf.thumbnail),
                    body: _node.node.acf.introduction,
                  }
                })
              : null
          }
        />
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    overlay: ui.overlay,
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(PageAbout)

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      path
      wordpress_id
      seo_meta {
        title
        description
      }
    }
    team: allWordpressWpTeam {
      edges {
        node {
          title
          id
          wordpress_id
          slug
          path
          acf {
            position
            image {
              ...ARYImageThumbnail
            }
            description
          }
        }
      }
    }
    projects: allWordpressWpPastproject {
      edges {
        node {
          title
          id
          wordpress_id
          slug
          path
          acf {
            introduction
            thumbnail {
              ...ARYImageThumbnail
            }
            date
            type
            description
            awards {
              award
            }
            people {
              person
              role
            }
            gallery {
              display
              image {
                ...ARYImageFullScreen
              }
              images {
                image {
                  ...ARYImageFullScreen
                }
              }
            }
          }
        }
      }
    }
  }
`
